import React, { Component } from 'react';
import Modal from 'react-modal';

import { GAME_BUILDER, MY_GAMES } from '../../../constants/routes';
import { withFirebase } from '../../../utils/Firebase';
import {
  deleteGame,
  shareGame,
  deleteEvent,
  copyGame,
} from '../../../utils/API/endpoints';
import GameOverview from '../../molecules/GameOverview/GameOverview';
import GamesList from '../../molecules/GamesList/GamesList';
import CreateEvent from '../../molecules/CreateEvent/CreateEvent';
import DeleteModal from '../../molecules/DeleteModal/DeleteModal';
import Scoreboard from '../../molecules/Scoreboard/Scoreboard';
import ShareGame from '../../molecules/ShareGame/ShareGame';
import {
  names,
  modalStrings,
  buttonStrings,
} from '../../variables/variables';

import {
  deleteModalStyles,
  customStylesModal,
  gameOverviewStyle,
  cancelDeleteButtonStyles,
  qrCodeModalStyles,
} from '../../molecules/GamesList/atoms/GamesModalStyles';
import gutta from '../../../static/banner-poplar-gutter.jpg';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { FaTimesCircle } from 'react-icons/fa';
import { NavigationContext, withNavigation } from '../../../utils/navigation/context';

class MyGames extends Component {
  static contextType = NavigationContext;
  state = {
    //page management
    errorMessage: '',
    games: [],
    inactiveEvents: [],
    activeEvents: [],
    unusedGames: [],
    libraryGames: [],
    selectedGame: undefined,
    isActivateGameModalOpen: false,
    isScoreboardModalOpen: false,
    isSearching: false,
    searchTerm: '',

    isJoinEventInfoModalVisible: false,

    isShowGameModalOpen: false,
    isDeleteEventModalOpen: false,
    eventToDelete: null,
    isDeleteGameModalOpen: false,
    gameToDelete: null,
    isShareGameModalOpen: false,

    gameId: null,
    eventId: null,
    urlGame: null,
    reactivate: false,
  };
  _initFirebase = false;
  gamesListenerUnsub = () => { /* Do nothing right now. */ };
  libraryGamesListenerUnsub = () => { /* Do nothing right now. */ };
  inactiveEventsListenerUnsub = () => { /* Do nothing right now. */ };
  activeEventsListenerUnsub = () => { /* Do nothing right now. */ };

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      const authUser = JSON.parse(localStorage.getItem('authUser'));

      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(document.location.search);
        if (params.has('share') && params.has('sharedGameId')) {
          const sharedGameId = params.get('sharedGameId');
          // Copy game to this account with firebase copy function.

          copyGame(this.props.firebase, {gameId: sharedGameId})
                .then((_) => {
                  window.alert(`Du har nå en kopi av spillet som var delt med deg.`);
                  typeof window !== 'undefined' && this.props.navigate(`${MY_GAMES}`);
                })
                .catch((err) => {
                  window.alert(`Kunne ikke kopiere spillet som var delt med deg.`);
                  console.log(err);
                });

          // this.props.firebase
          //   .game(gameId)
          //   .get()
          //   .then((game) => {
          //     if (game === null && game.data() === null) return;
          //     const gameData = game.data();
          //     this.props.firebase
          //       .games(authUser.uid)
          //       .add({
          //         ...gameData,
          //         created: new Date(),
          //         updated: new Date(),
          //         source: gameId,
          //       })
          //       .then((docRef) => {
          //         // Redirect to the new game.
          //         document.location.href = `/my-games?gameId=${docRef.id}`;
          //       });
          //   });

        }
      }


      this.gamesListenerUnsub = this.props.firebase
        .games(authUser.uid)
        .onSnapshot(
          (qs) => {
            var gameList = qs.docs.map((item) => {
              return {
                ...item.data(),
                id: item.id,
              };
            });
            gameList.sort(function (left, right) {
              var leftHas = left.hasOwnProperty('created');
              var rightHas = right.hasOwnProperty('created');
              if (leftHas && rightHas) {
                return right.created.seconds
                  .toString()
                  .localeCompare(left.created.seconds.toString());
              }
              return leftHas ? -1 : rightHas ? 1 : 0;
            });
            this.setState({
              games: gameList,
              loading: false,
              selectedGame: undefined,
              gameId: gameList[0]?.id || '',
            });
          },
          (err) => {
            // Kunne ikke laste inn dine spill.
            this.setState({ errorMessage: err });
          }, 
        );

      this.libraryGamesListenerUnsub = this.props.firebase
        .gamesLibrary()
        .onSnapshot(
          (qs) => {
            this.setState({
              libraryGames: qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              }),
              loading: false,
            });
          },
          (err) => {
            // Kunne ikke laste inn spill fra Poplar-biblioteket.
            this.setState({ errorMessage: err });
          },
        );

      this.inactiveEventsListenerUnsub = this.props.firebase
        .events(false, authUser.uid)
        .onSnapshot(
          (qs) => {
            let _inactiveEvents = qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              });
              _inactiveEvents.sort(function (left, right) {
                var leftHas = left.hasOwnProperty('created');
                var rightHas = right.hasOwnProperty('created');
                if (leftHas && rightHas) {
                  return right.created.seconds
                    .toString()
                    .localeCompare(left.created.seconds.toString());
                }
                return leftHas ? -1 : rightHas ? 1 : 0;
              });
            this.setState({
              inactiveEvents: _inactiveEvents,
              inactiveEventsLoading: false,
            });
          },
          (err) => {
            // Kunne ikke laste inn dine avslutta spill.
            this.setState({ errorMessage: err });
          },
        );
        this.activeEventsListenerUnsub = this.props.firebase
        .events(true, authUser.uid)
        .onSnapshot(
          (qs) => {
            let _activeEvents = qs.docs.map((item) => {
              return { ...item.data(), id: item.id };
            });
            _activeEvents.sort(function (left, right) {
              var leftHas = left.hasOwnProperty('created');
              var rightHas = right.hasOwnProperty('created');
              if (leftHas && rightHas) {
                return right.created.seconds
                  .toString()
                  .localeCompare(left.created.seconds.toString());
              }
              return leftHas ? -1 : rightHas ? 1 : 0;
            });
            this.setState({
              activeEvents: _activeEvents,
              activeEventsLoading: false,
            });
          },
          (err) => {
            // Kunne ikke laste inn dine avslutta spill.
            this.setState({ errorMessage: err });
          },
        );
      this.getUrlGame();
    }
  };

  async getUrlGame() {
    let params = new URLSearchParams(
      document.location.search.substring(1),
    );
    let gID = params.get('gameId');
    
    if (gID != null) {
      this.setState({
        gameId: gID,
        selectedGame: undefined,
        isActivateGameModalOpen: true,
      });
      this.getGame(gID);
    }
    let eID = params.get('eventId');
    
    if (eID != null) {
      this.setState({
        eventId: eID,
        selectedGame: undefined,
        isActivateGameModalOpen: true,
      });
    }

    if(gID === null && eID === null)
      this.setState({
        selectedGame: undefined,
        isActivateGameModalOpen: false,
      });
  };

  getGame = (gameId) => {
    this.props.firebase
      .game(gameId)
      .get()
      .then((game) => {
        if (game === null && game.data() === null) return;
        const gameData = game.data();
        this.setState({
          urlGame: gameData,
        });
      });
  };

  componentDidMount() {

    this.firebaseInit();
  }

  onShowGameInfo = (selectedObject) => {
    console.log("onShowGameInfo");
    this.setState({
      selectedGame: selectedObject,
      gameId: selectedObject.id,
      isShowGameModalOpen: true,
    });
  };

  onCloseGameInfo = () => {
    console.log("onCloseGameInfo");
    this.setState({
      isShowGameModalOpen: false,
      selectedGame: null,
      gameId: null,
    });
  };

  onEditEvent = (game, navigate) => {
    console.log("onEditEvent");
    typeof window !== "undefined" &&
    navigate(`${GAME_BUILDER}?eventId=${game.id}&outside=${game.outside}&update=true`);
  };


  onEditGame = (game, navigate) => {
    typeof window !== "undefined" &&
    navigate(`${GAME_BUILDER}?gameId=${game.id}&outside=${game.outside}&update=true`);
  };

  onShareGame = (game) => {
    console.log("onShareGame");
    this.setState({
      isShareGameModalOpen: true,
      selectedGame: game,
    });
  };

  onSendEmail = (game, email) => {
    console.log("onSendEmail");

    let gameVenue = game.type === "live" 
    ? "live" 
    : game.type === undefined || game.type === null 
        ? (game.outside ? "utendørs" : "innendørs") 
        : "utendørs";

        console.log( `https://app.poplar.no/my-games?share=true&sharedGameId=${game.id}`,
           game.name,
           gameVenue,
           `${game.questions.length}`,
           this.props.firebase.auth.currentUser.displayName ?? 'En venn',
           email)

    shareGame(this.props.firebase, {
        gameUri: `https://app.poplar.no/my-games?share=true&sharedGameId=${game.id}`,
        gameName: game.name,
        gameVenue: gameVenue,
        gameQuestions: `${game.questions.length}`,
        senderName: this.props.firebase.auth.currentUser.displayName ?? 'En venn',
        recipientEmail: email,
    });
    this.setState({
      isShareGameModalOpen: false,
      selectedGame: null,
    });
  };

  onDeleteGame = (game) => {
    console.log("onDeleteGame");
    this.setState({
      isDeleteGameModalOpen: true,
      gameToDelete: game,
    });
  };

  onEventShowInfo = (selectedObject, navigate) => {
    console.log("MyGames onEventShowInfo");
    navigate(`/results?eventId=${selectedObject.id}`);
    /*this.setState({
      selectedGame: selectedObject,
      gameId: selectedObject.id,
      isScoreboardModalOpen: true,
    });*/
  };

  onEventActivate = (selectedObject) => {
    console.log("onEventActivate");
    this.setState({
      selectedGame: selectedObject,
      gameId: selectedObject.id,
      isActivateGameModalOpen: true,
    });
  };

  onEventReActivate = (selectedObject, navigate) => {
    console.log("onEventReActivate");
    this.setState({
      selectedGame: selectedObject,
      eventId: selectedObject.id,
      reactivate: true,
      isActivateGameModalOpen: true,
    });
    typeof window !== "undefined" &&
    navigate(`${MY_GAMES}?eventId=${selectedObject.id}`);

  };

  onShowQRcode = (event) => {
    console.log("onShowQRcode");
    this.setState({
      isJoinEventInfoModalVisible: true,
      selectedEventCodeword: event.eventCode,
    });
    
    
  };
  
  onDeleteEvent = (event) => {
    this.setState({
      isDeleteEventModalOpen: true,
      eventToDelete: event,
    });
  };

  componentDidUpdate(_, prevState) {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.gamesListenerUnsub();
  }

  searchOnChange = (input) => {
    if (input && typeof input === 'string' && input.length > 0) {
      this.setState({
        isSearching: true,
        searchTerm: input.trim(),
      });
    } else {
      this.setState({
        isSearching: false,
        searchTerm: '',
      });
    }
  };

  filterGames = (games) => {
    //Only display unused games
    let unusedGames = games.filter(
      (game) =>
        !this.state.inactiveEvents
          .map((event) => event.source)
          .includes(game.id),
    );

    if (!this.state.isSearching) return unusedGames;
    const lowerFilter = (input, filter) =>
      input.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    return unusedGames.filter((s) => {
      return lowerFilter(s.name, this.state.searchTerm);
    });
  };

  filterEvents = (events) => {
    if (!this.state.isSearching) return events;
    const lowerFilter = (input, filter) =>
      input.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    return events.filter((s) => {
      return lowerFilter(s.name, this.state.searchTerm);
    });
  };

  closeScoreboardModal = (navigate) => {
    console.log("closeScoreboardModal");
    this.setState({
      isScoreboardModalOpen: false,
    });
    typeof window !== "undefined" && this.props.navigate(`${MY_GAMES}`);
  };

  openModal = () => {
    console.log("open modal");
    this.setState({
      isActivateGameModalOpen: true,
    });
  };

  closeModal = (navigate) => {
    console.log("close modal", typeof navigate);
    this.setState({
      isActivateGameModalOpen: false,
    });
    typeof window !== "undefined" && this.props.navigate(`${MY_GAMES}`);
  };

  closeJoinEventInfoModal = (navigate) => {
    this.setState({
      isJoinEventInfoModalVisible: false,
    });
    typeof window !== "undefined" && this.props.navigate(`${MY_GAMES}`);
  };

  render() {
    if (!this._initFirebase || this.state.loading)
      return <div>Laster inn...</div>;
    return (
      <>
      <main className="main">
        <div className='games-container container'>
          
              
      
            <GamesList
              title={names.libraryQuestions}
              libraryTitle={names.libraryTitle}
              userGames={this.filterGames(this.state.games)}
              userEvents={this.filterEvents(this.state.inactiveEvents)}
              userActiveEvents={this.filterEvents(this.state.activeEvents)}
              libraryGames={this.state.libraryGames}
              selectedObject={this.state.selectedGame}
              searchOnChange={this.searchOnChange}
              onGameShowInfo={this.onShowGameInfo}
              onGameEdit={this.onEditGame}
              onGameShare={this.onShareGame}
              onGameDelete={this.onDeleteGame}
              onGameActivateEvent={this.onEventActivate}
              onEditEvent={this.onEditEvent}
              onEventShowInfo={this.onEventShowInfo}
              onEventActivate={this.onEventActivate}
              onEventReActivate={this.onEventReActivate}
              onEventDelete={this.onDeleteEvent}
              onShowQRcode={this.onShowQRcode}
              isSuperAdmin={this.props.firebase.superAdmin}
              navigate={this.props.navigate}
             />
          

          {/* Game creator part 2, options and activate game */}
         
          <Modal
            isOpen={this.state.isActivateGameModalOpen}
            onRequestClose={() => this.closeModal(this.props.navigate)}
            contentLabel={modalStrings.activateQuestion}
           
            ariaHideApp={false}
           >
            <CreateEvent
              selectedObject={this.state.selectedGame}
              gameId={this.state.gameId}
              eventId={this.state.eventId}
              firebase={this.props.firebase}
              handleRequestClose={this.closeModal}
              reactivate={this.state.reactivate}
              navigate={this.props.navigate}
             />
          </Modal>

        

          <Modal
            isOpen={this.state.isScoreboardModalOpen}
            onRequestClose={this.closeScoreboardModal}
            contentLabel={modalStrings.showQuestion}
            ariaHideApp={false}
            style={customStylesModal}
           >
            <Scoreboard
              event={this.state.selectedGame}
              firebase={this.props.firebase}
              close={() => this.closeScoreboardModal()}
             />
          </Modal>

          {/*Delete game modal*/}
          <DeleteModal
            isOpen={this.state.isDeleteGameModalOpen}
            title="Er du sikker på at du ønsker å slette denne spillmalen?"
            text="Spillet kan da ikke gjenopprettes."
            buttonTitle={buttonStrings.deleteGame}
            onConfirm={() => {
              deleteGame(this.props.firebase, {
                'uid': this.state.gameToDelete.id,
                'gameId': this.state.gameToDelete.id,
              });
              this.setState({
                isDeleteGameModalOpen: false,
                gameToDelete: null,
              });
            }}
            onCancel={() => this.setState({
              isDeleteGameModalOpen: false,
              gameToDelete: null,
            })}
           />

          {/*Delete event modal*/}
          <DeleteModal
            isOpen={this.state.isDeleteEventModalOpen}
            title="Er du sikker på at du ønsker å slette resultatene fra dette spillet?"
            text = "Spillet blir da tilgjengelig i spillmaler (tittel kan da endre seg til spillmalens opprinnelige tittel)"
            buttonTitle="Slett resultater"
            onConfirm={() => {
              deleteEvent(this.props.firebase, {
                'uid': this.state.eventToDelete.id,
                'eventId': this.state.eventToDelete.id,
              });
              this.setState({
                isDeleteEventModalOpen: false,
                eventToDelete: null,
              });
            }}
            onCancel={() => this.setState({
              isDeleteEventModalOpen: false,
              eventToDelete: null,
            })}
           />

          { /* Show library game information. */ }
          <Modal
            isOpen={this.state.isShowGameModalOpen}
            onRequestClose={this.onCloseGameInfo}
            contentLabel="Opplysninger"
            style={gameOverviewStyle}
            ariaHideApp={false}
           >
            <GameOverview
              game={this.state.selectedGame}
              firebase={this.props.firebase}
              onRequestClose={this.onCloseGameInfo}
             />
          </Modal>

          { /* Share a game. */ }
          <Modal
            isOpen={this.state.isShareGameModalOpen}
            onRequestClose={() => this.setState({
              isShareGameModalOpen: false,
              selectedGame: null,
            })}
            ariaHideApp={false}
            style={deleteModalStyles}
           >
            <ShareGame
              onConfirm={this.onSendEmail}
              onCancel={() => this.setState({
                isShareGameModalOpen: false,
                selectedGame: null,
              })}
              game={this.state.selectedGame}
             />
          </Modal>


          <Modal
            isOpen={this.state.isJoinEventInfoModalVisible}
            onAfterOpen={() => {}}
            onAfterClose={() => this.setState({ selectedEvent: null })}
            onRequestClose={() => this.closeJoinEventInfoModal(this.props.navigate)}
            contentLabel="QrCode"
            style={qrCodeModalStyles}
            ariaHideApp={false}
          >
            <div ref={(el) => (this.componentRef = el)}>
              <img
              className='hide-mobile'
                style={{
                  width: '100%',
                  height: '10rem',
                  objectFit: 'cover',
                }}
                src={gutta}
                alt="Poplar-brukere"
              />
              <div className="qr-code-container">
                <p style={{ fontSize: '2rem', margin: '0.7rem' }}>
                  {modalStrings.howToPlay}
                </p>
                <p
                  className="hideFromPrint"
                  style={{ fontSize: '1.25rem', margin: '0.7rem' }}
                 >
                  {modalStrings.useCode}
                </p>
                <p
                  className="showInPrint"
                  style={{ fontSize: '1.25rem', margin: '0.7rem' }}
                 >
                  Skriv inn denne koden i appen:
                </p>
                <p
                  style={{
                    fontSize: '1.5rem',
                    color: '#EB7179',
                    margin: '0',
                  }}
                 >
                  &laquo;{this.state.selectedEventCodeword}&raquo;
                </p>
                <p style={{ fontSize: '1.25rem', margin: '2rem' }}>
                  Eller...
                </p>
                <p
                  className="hideFromPrint"
                  style={{ fontSize: '1.25rem' }}
                 >
                  {modalStrings.useQr}
                </p>
                <p
                  className="showInPrint"
                  style={{ fontSize: '1.25rem' }}
                 >
                  Skann denne QR koden med appen:
                </p>
                <img
                  src={encodeURI(
                    'https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=Poplar:' +
                      this.state.selectedEventCodeword,
                  )}
                  alt="QR-kode"
                 />
                <br />
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <button
                        className="hideFromPrint"
                        style={{
                          borderStyle: 'none',
                          backgroundColor: '#676B85',
                          margin: '2rem',
                          padding: '1rem 4rem',
                          borderRadius: '10rem',
                          color: 'white',
                        }}
                        onClick={handlePrint}
                       >
                        Print
                      </button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
              <button className={'hideFromPrint modal-exit-button'} onClick={() => this.closeJoinEventInfoModal(this.props.navigate)} style={cancelDeleteButtonStyles}>
                <FaTimesCircle className={'hideFromPrint'} style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
              </button>
            </div>
          </Modal>

        </div>
        
      </main>
      {/*
      <div className='activateGameModal' style={{display: this.state.isActivateGameModalOpen ? 'block':'none'}}>
        <div className='backdrop' onClick={() => this.setState({isActivateGameModalOpen: false})}></div>
          <div className='inner'>
            <CreateEvent
              selectedObject={this.state.selectedGame}
              gameId={this.state.gameId}
              eventId={this.state.eventId}
              firebase={this.props.firebase}
              handleRequestClose={this.closeModal}
             />
          </div>
        </div>
 */}
</>
    );
  }
}

export default withNavigation(withFirebase(MyGames));
